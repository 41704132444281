import React, {useState, useEffect} from 'react';
import quizQuestions from '../api/oldestQuestions';
import Quiz from '../QuizParts/Quiz';
// import Result from '../QuizParts/Result'
import { CSSTransition } from 'react-transition-group';
import '../../styles.css';
import ResultPage from '../QuizParts/ResultPage';
import Nav from '../Nav2';

function OldestQuiz(props) {

  const [counter, setCounter] = useState(0);
  const [questionId, setQuestionId] = useState(1);
  const [question, setQuestion] = useState(quizQuestions[0].question);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [answer, setAnswer] = useState('');
  const [answersCount, setAnswersCount] = useState({});
  const [result, setResult] = useState('');
  const [showQuiz, setShowQuiz] = useState(false);
  // const [showResult, setShowResult] = useState(false);
  // const [answerImg, setAnswerImg] = useState([]);
  const [burgerOn, setBurgerOn] = useState(false);
  // const [mainResult, setMainResult] = useState('');

    function openMenu(e) {
      e.target.id !== "my-burger" ? setBurgerOn(false) : setBurgerOn(!burgerOn); 
    }

  useEffect(() => {

    const shuffledAnswerOptions = quizQuestions.map((question) => shuffleArray(question.answers))  

    const answer = shuffledAnswerOptions[0]
    console.log(answer)
    setAnswerOptions(answer)
    setTimeout(() => setShowQuiz(true), 300)
  },[])
  
  // function shuffleQuestions(array) {
  //   array.sort(() => Math.random() -0.5)
  // }
  
  // --------------------randomly shuffle remaining answers--------------------- 
  function shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    } 
    
    return array;
  }

// count how many answers and add them to  state works off bool check AnswerOptions.js
  function setUserAnswer(answer) {
    setAnswersCount({
        ...answersCount,
      [answer]: (answersCount[answer] || 0) +1
    })
  }
  // --------------------when answer is selected set next---------------------
  // function handleAnswerSelected(event) {
  //   setUserAnswer(event.currentTarget.value);
  //   if (questionId < quizQuestions.length) {
  //       setTimeout(() => setNextQuestion(), 300)
  //     } else {
  //       // do nothing for now
  //       setTimeout(() => setResults(getResults()), 300)
        
  //     }
  // }

  function handleLiSelected(event) {
    setUserAnswer(event.currentTarget.id);
    if (questionId < quizQuestions.length) {
        setTimeout(() => setNextQuestion(), 300)
        
      } else {
        // do nothing for now
        setTimeout(() => setResults(getResults()), 300)
        // could trigger link for resultpage to appear
        // setTimeout(() => setShowResult(true), 100)
      }
  }
  
  function setNextQuestion() {
    const count = counter + 1;
    const qId = questionId + 1;

    setCounter(count)
    
    setQuestionId(qId)
    setQuestion(quizQuestions[count].question)
    setAnswerOptions(quizQuestions[count].answers)
    setAnswer('')
  }

  function getResults() {

    // Which answers were selected
    const answersCountKeys = Object.keys(answersCount);
    // console.log(answersCountKeys)

    // how many times were each answer selected
    const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
    // console.log(answersCountValues)

    // return highset answerCountValues
    const maxAnswerCount = Math.max.apply(null, answersCountValues);
    // console.log(maxAnswerCount)
    // console.log(maxAnswerCount)
    // filter answers and return answer with highest choice
    return answersCountKeys.filter((key) => answersCount[key] === maxAnswerCount);
    
  }

//  -----how it is returning answer------
function setResults (result) {
    // console.log(result + 'and' + result.length)
    if (result.length === 1) {
      // .replace(/[ \W+/g ]/)
      setResult(result)
      // console.log(result + 'one')
    //   props.firebase.db.ref(`users/${uid}`).child('house').set(`${result}`)
    } else {
      // take last item in array
       const newResult = result.shift()
      //  console.log(result)
      setResult(newResult)
      // console.log(newResult + 'two')
    //   props.firebase.db.ref(`users/${uid}`).child('house').set(`${newResult}`)
  }
  // setTimeout(() => setShowResult(true), 100)
  
  }
  
  function renderQuiz() {
    
    return (
      <div>
        <Nav
            burgerOn={burgerOn}
            openMenu={openMenu}
          />
      <CSSTransition
      in={showQuiz}
      timeout={300}
      classNames="quiz-transition"
      mountOnEnter
      unmountOnExit
      appear
      >
        <>
        {/* <Nav
            burgerOn={burgerOn}
            openMenu={openMenu}
          /> */}
          <h1>10-12 educational quiz</h1>
        <Quiz
          counter={counter}
          answer={answer}
          answerOptions={answerOptions}
          questionId={questionId}
          question={question}
          questionTotal={quizQuestions.length}
          handleLiSelected={handleLiSelected}
        />
      {/* <Footer /> */}
      </>
    </CSSTransition>
    </div>
    );
  }

  // make link to result page
  // const renderResultPage = (props) => {
  //   return(
  //       <>
  //     <CSSTransition
  //     in={showQuiz}
  //     timeout={300}
  //     classNames="result-transition"
  //     mountOnEnter
  //     unmountOnExit
  //     appear
  //     >
  //     <div>
  //       <li className="get-result">
  //         <Link to={ROUTES.RESULT}>Get Results</Link>
  //       </li>
  //       </div>
  //         </CSSTransition>
  //       </>
  //   )
  // }
  // need to create link to go to  resultPage
  const renderResult = (props) => {
    return (
      <>
      <Nav
            burgerOn={burgerOn}
            openMenu={openMenu}
          />
      <ResultPage 
      quizResult={result}
      />
      </>
    )
  }

  return(
    <div className="quiz-cont" onClick={openMenu}>
        { result ? renderResult() : renderQuiz() }
        {/* {renderQuiz()} */}
        {/* { result ? renderResultPage() : '' } */}
      </div>
  )
}

export default OldestQuiz