import React, {lazy, Suspense} from 'react';
// import Landing from '../Landing';

const LazyQuiz1 = lazy(() => import('../Quiz1'));
const FooterComponent = lazy(() => import('../Footer'));

const renderLoader = () => <p className="loading">Loading</p>;

 const QuizLoading = () => (
        <Suspense fallback={renderLoader()}>
            <LazyQuiz1 />
            <FooterComponent />
        </Suspense>
    )

export default QuizLoading;