var adultQuestions = [
    {
        question: "In my ideal job, it is important that:",
        answers: [
            {
                type: "Planner",
                content: "I decide on process and end reults",
                img:  "../house.svg"
            },
            {
                type: "Helper",
                content: "I am independant",
                img:"../wrench.svg"
            },
            {
                type: "Finder",
                content: "I discover solutions to problems",
                img:  "../finder.svg"
            },
            {
                type: "Builder",
                content: "I make use of my abilities",
                img:  "../hammer.svg"
            },
            {
                type: "Creator",
                content: "I try out my own ideas",
                img: "../brush.svg"
            }
        ]
    },
    {
        question: "I enjoy most...",
        answers: [
            {
                type: "Planner",
                content: "Preparing for and carrying out large projects",
                img: "../house.svg"
            },
            {
                type: "Helper",
                content: "Being a skilled asset for my company",
                img: "../wrench.svg"
            },
            {
                type: "Finder",
                content: "Researching for answering",
                img: "../finder.svg"
            },
            {
                type: "Builder",
                content: "Seeing immediate results for my work",
                img: "../hammer.svg"
            },
            {
              type: "Creator",
              content: "Variety and lots of smaller projects",
              img: "../brush.svg"
          }
        ]
    },
    {
        question: "My weaknesses are",
        answers: [
            {
                type: "Planner",
                content: "I get frustrated when i am not in charge",
                img: "../house.svg"
            },
            {
                type: "Helper",
                content: "I get overwhelmed and need alone time",
                img: "../wrench.svg"
            },
            {
                type: "Finder",
                content: "I get bored easily",
                img: "../finder.svg"
            },
            {
                type: "Builder",
                content: "I get frustrated when others cut corners",
                img: "../hammer.svg"
            },
            {
              type: "Creator",
              content: "I get distracted easily",
              img: "../brush.svg"
          }
        ]
    },
    {
        question: "I would describe myself as",
        answers: [
            {
                type: "Planner",
                content: "A leader",
                img: "../house.svg"
            },
            {
                type: "Helper",
                content: "Capable",
                img: "../wrench.svg"
            },
            {
                type: "Finder",
                content: "Curios",
                img: "../finder.svg"
            },
            {
                type: "Builder",
                content: "A doer",
                img: "../hammer.svg"
            },
            {
              type: "Creator",
              content: "An artist",
              img: "../brush.svg"
          }
        ]
    },
    {
        question: "I am more often",
        answers: [
            {
                type: "Planner",
                content: "A fast decision maker focused on people",
                img: "../house.svg"
            },
            {
                type: "Helper",
                content: "A slow decision maker focused on people",
                img: "../wrench.svg"
            },
            {
                type: "Finder",
                content: "Both a slow and fast decision maker being people and task focused",
                img: "../finder.svg"
            },
            {
                type: "Builder",
                content: "A fast decision maker focused on tasks",
                img: "../hammer.svg"
            },
            {
              type: "Creator",
              content: "A slow decision maker focused on tasks",
              img: "../brush.svg"
          }
        ]
    },
    {
        question: "oldest I don't like",
        answers : [
          {
              type: "Planner",
              content: "To work alone",
              img: "../house.svg"
          },
          {
              type: "Helper",
              content: "To work inside",
              img: "../wrench.svg"
          },
          {
              type: "Finder",
              content: "Doing the same thing everyday",
              img: "../finder.svg"
          },
          {
            type: "Builder",
            content: "Having no rules",
            img: "../hammer.svg"
            },
          {
            type: "Creator",
            content: "Following rules",
            img: "../brush.svg"
        }
        ]
    }
  ];
   
  export default adultQuestions;

