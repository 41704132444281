import React
// ,{useState} 
from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import icon from "../../img/icon.svg";
import NavBtn from "../NavBtn";
import Menu from '../Menu';
// look into hookRouter

function Nav(props) {
return (
    <>
    <div className="header">
        <a href="https://www.hughesgc.com/" className="icon">
            <img  src={icon} alt="icon" height="30" width="125"/>
        </a>
    {/* <div className="burger"  onClick={clickIt}>≡</div> */}
        <NavBtn
        burgerOn={props.burgerOn}
        openMenu={props.openMenu}
        />
        
            <ul className={props.burgerOn ? "navContainer isActive" : 'navContainer'}>
                <li>
                <Link to={ROUTES.LANDING}>Home</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ1}>K-6</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ2}>7-9</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ3}>10-12</Link>
                </li>
            </ul>
            <Menu></Menu>
            {/* <Link to={ROUTES.QUIZ} className="icon">
                <img  src={icon} alt="icon"/>
            </Link> */}
    </div>
    <div className="clear"></div>
    </>
);
}

export default Nav;