import React from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

 function NoMatch() {
    return (
        <>
        <div>
            <h3>Sorry we don't recognize your search, We must've got mixed up!</h3>
            <span>These links might help</span>
        </div>
        <div className="error-links">
            <div>
            <Link to={ROUTES.LANDING}>Home</Link>
            </div>
            <br></br>
            <div>
            <Link to={ROUTES.QUIZ1}>quiz</Link>
            </div>
        </div>
        </>
    )
}

export default NoMatch