// Do Not edit
import React from 'react'

 function ResultPage(props) {
    let job1 = ''
    let job2 = ''
    let job3 = ''
    let job4 = ''
    let link = ""
    // const [quizResult, setQuizResult] = useState('');
    // const [job1, setjob1] = useState('');
    // const [Link, setLink] = useState('');
    // let link = ''
    // useEffect((props) => {
    //   const quizResult = props.quizResult.toString() || ""
    //   setQuizResult(quizResult)
    // },[])

    

    switch(props.quizResult.toString()) {
      case "Planner": 
        job1 = 'Project Manager';
        job2 = 'Superintendent';
        job3 = '';
        job4 = '';
        link = 'https://hughesgc.squarespace.com/student-resources';
       break;
      case "Finder":
        job1 = 'Lab Technician';
        job2 = 'Estimator';
        job3 = '';
        job4 = '';
        link = 'https://hughesgc.squarespace.com/student-resources';
        break;
      case "Helper":
        job1 = 'Drone Pilot';
        job2 = 'Attorney';
        job3 = 'CDL Driver';
        job4 = 'Heavy Equipment Opperator';
        link = 'https://hughesgc.squarespace.com/student-resources';
        break;
      case "Builder":
        job1 = 'Mechanic';
        job2 = 'Carpenter';
        job3 = 'Concrete Finisher';
        job4 = '';
        link = 'https://hughesgc.squarespace.com/student-resources';
        break;
      case "Creator":
        job1 = '3D Modeler';
        job2 = 'Graphic Designer';
        job3 = 'Engineer';
        job4 = '';
        link = 'https://hughesgc.squarespace.com/student-resources';
        break;
      default: 
        job1 = '';
        job2 = '';
        job3 = '';
        job4 = '';
        link = 'https://hughesgc.squarespace.com/student-resources';
    }

  return (
    <>
    <h1>Result</h1>
    <div className="result">
       <div className="job-title">You might like being a <strong>{props.quizResult}</strong> !
    </div>
       <div >{`Find out more about ${props.quizResult}s`}</div>
       <div className="profession">
        <div className="prof">{job1}</div>
        <div className="prof">{job2}</div>
        <div className="prof">{job3}</div>
        <div className="prof">{job4}</div>
       </div>
       <div className="job-links">
          <a href={link} >Click</a> for more info!
       </div>
    </div>
    
    </>
  );
}


export default ResultPage