import React, {lazy, Suspense} from 'react';
// import Landing from '../Landing';

const LazyQuiz3 = lazy(() => import('../Quiz3'));
const FooterComponent = lazy(() => import('../Footer'));

const renderLoader = () => <p className="loading">Loading</p>;

 const QuizLoading = () => (
        <Suspense fallback={renderLoader()}>
            <LazyQuiz3 />
            <FooterComponent />
        </Suspense>
    )

export default QuizLoading;