import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import QuestionCount from './QuestionCount';
import AnswerOption from './AnswerOption';
// import { CSSTransition } from 'react-transition-group';
import '../../styles.css';

function Quiz(props) {

  const [counter, setCounter] = useState('');

useEffect(() => {
  const questionId = props.questionId || ''
  
    setCounter(questionId)
},[props.questionId])
  
    function renderAnswerOptions(key) {
      // console.log(key.img)
        return (
          <AnswerOption
            key={key.content}
            answerContent={key.content}
            answerType={key.type}
            // answer={props.answer}
            questionId={props.questionId}
            onAnswerSelected={props.onAnswerSelected}
            handleLiSelected={props.handleLiSelected}
            answerImg={key.img}
          />
        );
      }

    return (
      <>
      <div 
      key={props.questionId}
      className="quiz"
      >
        
          <QuestionCount
            counter={counter || ''}
            total={props.questionTotal}
          />
          <Question content={props.question} />
          <ul className="answerOptions">
            {props.answerOptions.map(
              renderAnswerOptions
              )}
          </ul>
        </div>
        </>
    );
  }
  
  Quiz.propTypes = {
    answer: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired,
    counter: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTotal: PropTypes.number.isRequired,
    // onAnswerSelected: PropTypes.func.isRequired
  };
  
  export default Quiz;
