import React from 'react';
import PropTypes from 'prop-types';

function AnswerOption(props) {
  // console.log(props.answerImg)
  return (
    <li className="answerOption"
      title={props.answerContent}
      value={props.answerType}
      id={props.answerType}
      onClick={props.handleLiSelected}
    >
      <button>{props.answerContent}</button>
      {/* <a> */}
      {/* <img src={props.answerImg || ""} height="25" width="25" alt=""/> */}
      {/* {props.answerContent}
      </a> */}

    </li>
  );
}

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  // answer: PropTypes.string.isRequired,
  // onAnswerSelected: PropTypes.func.isRequired
};

export default AnswerOption;