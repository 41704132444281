import React from 'react';

 function NavBtn(props) {
    return (
        <div 
             onClick={props.openMenu}
            className={props.burgerOn ? "burger-menu-clicked":'burger-menu'}
            id="my-burger"
        > 
            <div className="burger-item-1" id="my-burger"></div>
            <div className="burger-item-2" id="my-burger"></div>
            <div className="burger-item-3" id="my-burger"></div>
            <div className="burger-item-4" id="my-burger"></div>
            {/* <div id="my-burger"></div>
            <div id="my-burger"></div>
            <div id="my-burger"></div> */}
        </div>
    )
}

export default NavBtn