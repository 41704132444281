import React, {lazy, Suspense} from 'react'

const FooterComponent = lazy(() => import('../Footer'));
const LandingComponent = lazy(() => import('../Landing'));
// const NavComponent = lazy(() => import('../Navigation'));

const renderLoader = () => (
    
    <div className="loading">
    <h2>Loading</h2>;
    </div>
)
 const Home = () => (
    <Suspense fallback={renderLoader()}>
        <LandingComponent />
        <FooterComponent />
    </Suspense>
 )
export default Home;