import React
// ,{useState} 
from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

function Menu (props) {
    return (
    <ul className="menu">
                <li>
                <Link to={ROUTES.LANDING}>Home</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ1}>K-6</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ2}>7-9</Link>
                </li>
                <li>
                <Link to={ROUTES.QUIZ3}>10-12</Link>
                </li>
            </ul>
    )
}

export default Menu;