import React from 'react';
import { 
  // BrowserRouter as Router,
  HashRouter as HRouter,
  Route,
  Switch,
} from 'react-router-dom';
// import Navigation from '../Navigation';
import * as ROUTES from '../../constants/routes';
import '../../mysass.scss';
// routes
import Home from '../Home';
// import LandingPage from '../Landing';
// import Quiz from '../Quiz1';
// import MidQuiz from '../Quiz2';
import LastQuiz from '../Quiz3';
import ResultPage from '../QuizParts/ResultPage';
import NoMatch from '../NoMatch';
// import '../../styles.css';
import QuizLoading from '../QuizLoading';
import QuizLoading2 from '../QuizLoading2';
import QuizLoading3 from '../QuizLoading3';
// import Footer from "../Footer";

function App() {
  // const [burgerOn, setBurgerOn] = useState(false);
  // // const [mainResult, setMainResult] = useState('');

  //   function openMenu(e) {
  //     e.target.id !== "my-burger" ? setBurgerOn(false) : setBurgerOn(!burgerOn); 
  //   }


    return (
      <div 
      className="app-area" 
      // onClick={openMenu}
      >
        <HRouter>
          {/* <Navigation
            burgerOn={burgerOn}
            openMenu={openMenu}
          /> */}
          <Switch>
            <Route exact path={ROUTES.LANDING} component={Home} />
            <Route path={ROUTES.QUIZ1} component={QuizLoading} />
            <Route path={ROUTES.QUIZ2} component={QuizLoading2} />
            <Route path={ROUTES.QUIZ3} component={QuizLoading3} />
            {/* <Route path={ROUTES.QUIZ1} component={Quiz} /> */}
            {/*<Route path={ROUTES.QUIZ2} component={MidQuiz} />*/}
            <Route path={ROUTES.QUIZ3} component={LastQuiz} />
            <Route path={ROUTES.RESULT} component={ResultPage} />
            <Route  path={ROUTES.NOMATCH} component={NoMatch} />
            {/* <Route  component={NoMatch} /> */}
            {/* <Route path={ROUTES.HP_QUIZ} component={Hpquiz} /> */}
          </Switch>
        </HRouter>
        {/* <div className="footer"><h1>footer</h1></div> */}
        {/* <Footer /> */}
        </div>
    )
  }
  
  export default App